import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  server: {
    GOOGLE_RESERVE_USERNAME: z.string(),
    S3_BUCKET: z.string(),
    AWS_ACCESS_KEY_ID: z.string(),
    AWS_SECRET_ACCESS_KEY: z.string(),
    GOOGLE_RESERVE_PASSWORD: z.string(),
    GOOGLE_RESERVE_SERVICES_FEED_USER: z.string(),
    GOOGLE_RESERVE_MERCHANTS_FEED_USER: z.string(),
    GOOGLE_RESERVE_AVAILABILITY_FEED_USER: z.string(),
    GOOGLE_RESERVE_PARTNER_ID: z.string(),
    GOOGLE_RESERVE_ENDPOINT: z.string(),
    GOOGLE_RESERVE_SERVICE_ACCOUNT: z.string(),
    GOOGLE_RESERVE_BUCKET_URL: z.string(),
    GOOGLE_RESERVE_BUCKET_PORT: z.string(),
    GOOGLE_RESERVE_PRIVATE_KEY: z.string(),
    MOLLIE_APP_ID: z.string(),
    MOLLIE_APP_SECRET: z.string(),
    MOLLIE_API_KEY: z.string(),
    MOLLIE_AUTH_ENDPOINT: z.string().url(),
    MOLLIE_WEBSITE_PROFILE: z.string(),
    MOLLIE_OAUTH_SECRET: z.string(),
    MOLLIE_REDIRECT_URI: z.string().url(),
    MOLLIE_ACCESS_TOKEN: z.string(),
    MOLLIE_WEBHOOK_URL: z.string().url(),
    MOLLIE_ONBOARDING_REDIRECT_URI: z.string().url(),
    MONEYBIRD_TOKEN: z.string(),
    MONEYBIRD_ADMINISTRATION: z.string(),
    MONEYBIRD_WORKFLOW_ID: z.string(),
    MONEYBIRD_DOCUMENT_STYLE_ID: z.string(),
    MONEYBIRD_TAX_RATE_ID: z.string(),
    SMTP_HOST: z.string(),
    SMTP_USER: z.string(),
    SMTP_PASSWORD: z.string(),
    SMTP_PORT: z.string(),
    PUSHOVER_TOKEN: z.string(),
    PUSHOVER_MANAGERS_GROUP: z.string(),
    PUSHOVER_TESTERS_GROUP: z.string(),
    SUPABASE_SERVICE_ROLE_KEY: z.string(),
  },

  client: {
    NEXT_PUBLIC_POSTHOG_API_KEY: z.string(),
    NEXT_PUBLIC_URL: z.string(),
    NEXT_PUBLIC_POSTHOG_HOST: z.string(),
    NEXT_PUBLIC_SUPABASE_URL: z.string(),
    NEXT_PUBLIC_SUPABASE_ANON_KEY: z.string(),
    NEXT_PUBLIC_BRANCH: z.string(),
    NEXT_PUBLIC_MARKETING_URL: z.string(),
    NEXT_PUBLIC_MAPS_API_KEY: z.string(),
  },

  /**
   * What object holds the environment variables at runtime. This is usually
   * `process.env` or `import.meta.env`.
   */
  experimental__runtimeEnv: {
    NEXT_PUBLIC_POSTHOG_API_KEY: process.env.NEXT_PUBLIC_POSTHOG_API_KEY,
    NEXT_PUBLIC_URL: process.env.NEXT_PUBLIC_URL,
    NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    NEXT_PUBLIC_SUPABASE_URL: process.env.NEXT_PUBLIC_SUPABASE_URL,
    NEXT_PUBLIC_SUPABASE_ANON_KEY: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
    NEXT_PUBLIC_BRANCH: process.env.NEXT_PUBLIC_BRANCH,
    NEXT_PUBLIC_MARKETING_URL: process.env.NEXT_PUBLIC_MARKETING_URL,
    NEXT_PUBLIC_MAPS_API_KEY: process.env.NEXT_PUBLIC_MAPS_API_KEY,
  },

  /**
   * By default, this library will feed the environment variables directly to
   * the Zod validator.
   *
   * This means that if you have an empty string for a value that is supposed
   * to be a number (e.g. `PORT=` in a ".env" file), Zod will incorrectly flag
   * it as a type mismatch violation. Additionally, if you have an empty string
   * for a value that is supposed to be a string with a default value (e.g.
   * `DOMAIN=` in an ".env" file), the default value will never be applied.
   *
   * In order to solve these issues, we recommend that all new projects
   * explicitly specify this option as true.
   */
  emptyStringAsUndefined: true,
});
