import React, { useEffect } from "react";
import { usePlacesWidget } from "react-google-autocomplete";

import { Input } from "@vrodex/ui/input";

import { env } from "~/env";

export default function GoogleAddressAutoComplete({
  country,
  onChange,
  onPlaceSelected,
  value,
  className,
}: {
  country: string;
  onChange?: (value: string) => void;
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void;
  value?: string;
  className?: string;
}) {
  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: env.NEXT_PUBLIC_MAPS_API_KEY,
    onPlaceSelected: (place) => onPlaceSelected(place),
    options: {
      types: ["address"],
      componentRestrictions: { country: country },
      fields: ["address_component", "name"],
    },
  });

  useEffect(() => {
    setTimeout(() => {
      document.body.style.pointerEvents = "";
    }, 0);
  }, []);
  return (
    <Input
      className={className}
      value={value}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.value);
        }
      }}
      role="presentation"
      autoComplete="off"
      ref={ref}
    />
  );
}
