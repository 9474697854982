/* eslint-disable @typescript-eslint/unbound-method */
import type { z } from "zod";

import { GoogleFetchAddressSchema } from "@vrodex/validators";

import { env } from "~/env";

export const getAddress = async (
  data: z.infer<typeof GoogleFetchAddressSchema>,
) => {
  const parsed = GoogleFetchAddressSchema.safeParse(data);

  if (!parsed.success) {
    return null;
  }

  const input = parsed.data;

  const address = encodeURIComponent(
    `${input.streetName} ${input.streetNumber} ${input.zipCode}`,
  );

  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?key=${env.NEXT_PUBLIC_MAPS_API_KEY}&address=${address}`,
  );

  if (!response.ok) {
    console.error(response.statusText);
    return null;
  }

  const { results } = (await response.json()) as google.maps.GeocoderResponse;

  const location = results.find((result) => result.geometry.location);

  if (!location) {
    return null;
  }
  return {
    lat: +location.geometry.location.lat,
    lng: +location.geometry.location.lng,
  };
};
