import { useTranslations } from "next-intl";
import { isValidPhoneNumber } from "react-phone-number-input";
import { z } from "zod";

import type {
  BookingStatus,
  CashLocationMutationType,
  ModuleTypes,
  Permissions,
  ProductModifierTypes,
  ProductModifierValueType,
  Status,
  SupportedLanguages,
  TableShapes,
  TableTypes,
} from "@vrodex/db";
import { OrderLineTypes, PaymentStatus } from "@vrodex/db";
import { formatIntToCurrency } from "@vrodex/utils";
import { PaymentSchema } from "@vrodex/validators";

import type { CartItemColorDisplay } from "~/types";

export const use18nSchema = () => {
  const t = useTranslations("Validators");

  const ProductSchema = z.object({
    name: z.string().min(1, { message: t("Product.name.min_length") }),
    preparationName: z.string().optional(),
    regularPrice: z.number({
      required_error: t("Product.regular_price.required"),
    }),
    status: z.custom<Status>(),
    pickupPrice: z.number().optional(),
    color: z.string().optional(),
    position: z.number(),
    category: z
      .string({ required_error: t("Product.category.required") })
      .uuid(),
    course: z
      .string()
      .uuid()
      .optional()
      .or(z.literal("default"))
      .transform((val) => (val === "default" ? undefined : val)),
    vatRate: z
      .string({ required_error: t("Product.vat_rate_required") })
      .uuid(),
    printRoute: z
      .string()
      .uuid()
      .or(z.literal("default"))
      .transform((val) => (val === "default" ? undefined : val)),
    description: z.string().optional(),
  });

  const PrintRouteSchema = z.object({
    name: z.string().min(1, { message: t("Product.name.min_length") }),
    status: z.custom<Status>(),
    color: z.string().optional(),
    printers: z.array(z.string().uuid()).min(1),
  });

  const CourseSchema = z.object({
    name: z.string().min(1, { message: t("Product.name.min_length") }),
    status: z.custom<Status>(),
    color: z.string().optional(),
  });

  const RegisterSettingsSchema = z.object({
    cartItemColorDisplay: z.custom<CartItemColorDisplay>(),
  });

  const ProductCategorySchema = z.object({
    name: z.string().min(1, t("ProductCategory.name_min_length")),
    description: z.string().optional(),
    position: z.number(),
    status: z.custom<Status>(),
    color: z.string().optional(),
    printRoute: z
      .string()
      .uuid()
      .optional()
      .or(z.literal(""))
      .transform((val) => (val === "" ? undefined : val)),
    course: z
      .string()
      .uuid()
      .optional()
      .or(z.literal(""))
      .transform((val) => (val === "" ? undefined : val)),
    vatRate: z
      .string({ required_error: t("Product.vat_rate_required") })
      .uuid(),
  });

  const SetupFormSchema = z.object({
    companyName: z.string(),
    country: z.string(),
    zipCode: z.string(),
    streetName: z.string(),
    streetNumber: z.string(),
    lat: z.number(),
    lon: z.number(),
    city: z.string(),
    modules: z
      .array(z.custom<ModuleTypes>())
      .min(1, t("Setup.module_min_length")),
  });

  const BookingSchema = z.object({
    customer: z.object({
      firstName: z
        .string()
        .optional()
        .or(z.literal(""))
        .transform((val) => (val === "" ? undefined : val)),
      lastName: z
        .string()
        .optional()
        .or(z.literal(""))
        .transform((val) => (val === "" ? undefined : val)),
      email: z
        .string()
        .email()
        .optional()
        .or(z.literal(""))
        .transform((val) => (val === "" ? undefined : val)),
      phone: z
        .string()
        .optional()
        .or(z.literal(""))
        .transform((val) => (val === "" ? undefined : val))
        .superRefine((phone, ctx) => {
          if (!phone) return phone;
          if (!isValidPhoneNumber(phone)) {
            ctx.addIssue({
              code: "custom",
              message: t("Booking.invalid_phone"),
            });
          }
        }),
    }),
    table: z.string().uuid().optional(),
    label: z.string().uuid().optional(),
    duration: z.number(),
    memo: z.string().optional(),
    groupSize: z.number(),
    date: z.date(),
    status: z.custom<BookingStatus>(),
  });

  const OrderCommentSchema = z.object({
    comment: z.string().min(1, { message: t("OrderComment.min_length") }),
    printRoute: z
      .string({ required_error: "Select a print route" })
      .uuid("Select a print route"),
  });

  const OrderLineSchema = z.array(
    z.object({
      reference: z.string().optional().nullable(),
      productId: z.string().uuid().optional(),
      createdAt: z.string().optional(),
      printerId: z.string().uuid().optional(),
      printRouteId: z.string().uuid().optional(),
      serviceId: z.string().uuid().optional(),
      type: z.custom<OrderLineTypes>().optional(),
      name: z.string(),
      preparationName: z.string().optional(),
      parent: z.string().optional(),
      course: z
        .object({
          id: z.string().uuid(),
          name: z.string(),
        })
        .optional(),
      category: z
        .object({
          id: z.string().uuid(),
          name: z.string(),
        })
        .optional(),
      position: z.number(),
      paymentStatus: z.custom<PaymentStatus>(),
      quantity: z.number(),
      price: z.number(),
      vatRate: z.number().optional(),
      internalMemo: z.string().optional(),
      comment: z.string().optional(),
    }),
  );

  const CheckoutSchema = z
    .object({
      order: z
        .object({
          reference: z.string().optional(),
          table: z.string().uuid().optional(),
        })
        .optional(),
      drawer: z.string().uuid().optional(),
      employee: z.string().uuid().optional(),
      checkoutLines: OrderLineSchema,
      payment: PaymentSchema,
    })
    .superRefine((val, ctx) => {
      const totalPaid = val.payment.reduce((acc, curr) => acc + curr.amount, 0);

      const itemsTotal = val.checkoutLines
        .filter(
          (line) =>
            (line.type === OrderLineTypes.product ||
              line.type === OrderLineTypes.extra) &&
            line.paymentStatus === PaymentStatus.in_progress,
        )
        .reduce((total, line) => total + line.price * line.quantity, 0);

      const discountTotal = val.checkoutLines
        .filter(
          (line) =>
            line.type === OrderLineTypes.discount &&
            line.paymentStatus === PaymentStatus.in_progress,
        )
        .reduce((total, line) => total + line.price * line.quantity, 0);

      const totalToPay = itemsTotal - discountTotal;

      if (totalPaid < totalToPay) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          minimum: totalToPay,
          type: "number",
          message: t("Checkout.payment_less_than_total", {
            amount: formatIntToCurrency(totalToPay, true),
          }),
          inclusive: true,
          path: ["payment"],
        });
      }
    });

  const MiscProductSchema = z.object({
    name: z.string().min(1, { message: t("MiscProduct.name.min_length") }),
    price: z.number({ required_error: t("General.required") }),
    vatRate: z.number(),
    course: z
      .string()
      .uuid()
      .or(z.literal("default"))
      .transform((val) => (val === "default" ? undefined : val)),
    printRoute: z.string().uuid({ message: "Select a print route" }),
  });

  const CompanySettingsFormSchema = z.object({
    userId: z.string().uuid(),
    companyName: z.string(),
    phoneNumber: z.string().superRefine((phone, ctx) => {
      if (!isValidPhoneNumber(phone)) {
        ctx.addIssue({
          code: "custom",
          message: t("General.invalid_phone"),
        });
      }
    }),
    zipCode: z.string(),
    streetName: z.string(),
    streetNumber: z.string(),
    city: z.string(),
    country: z.string(),
  });

  const EmployeeSchema = z.object({
    firstName: z.string().min(1, t("Employees.first_name_min_length")),
    lastName: z.string().optional(),
    language: z.custom<SupportedLanguages>(),
    code: z.string().min(1, t("Employees.code_min_length")),
    email: z.string().optional(),
    hourlyRate: z.number().optional(),
    dateOfBirth: z.date().optional(),
    employedSinceDate: z.date().optional(),
    color: z.string().optional(),
    group: z.string().uuid(t("Employees.invalid_group")),
  });

  const EmployeeGroupSchema = z.object({
    name: z.string().min(1, t("EmployeeGroup.name_min_length")),
    permissions: z.array(z.custom<Permissions>()),
  });

  const ProductExtraSchema = z.object({
    name: z.string().min(1, { message: t("Product.name.min_length") }),
    regularPrice: z.number({
      required_error: t("Product.regular_price.required"),
    }),
    vatRate: z.number(),
    type: z.custom<ProductModifierTypes>(),
    valueType: z.custom<ProductModifierValueType>(),
  });

  const MutationSchema = z.object({
    value: z.number({ required_error: t("General.required") }),
    cashLocation: z
      .string({ required_error: t("General.required") })
      .uuid(t("General.required")),
    category: z
      .string({ required_error: t("General.required") })
      .uuid(t("General.required")),
    type: z.custom<CashLocationMutationType>(),
    comment: z.string().optional(),
    vatRate: z
      .string({ required_error: t("General.required") })
      .uuid(t("General.required")),
    employee: z.string().uuid().optional(),
  });

  const TableSchema = z.object({
    reference: z.string().min(1, t("Table.reference_min_length")),
    seats: z.number(),
    location: z
      .string({ required_error: t("General.required") })
      .uuid(t("General.required")),
    width: z.number(),
    height: z.number(),
    posX: z.number(),
    posY: z.number(),
    rotation: z.number(),
    shape: z.custom<TableShapes>(),
    type: z.custom<TableTypes>(),
    status: z.custom<Status>(),
  });

  const LocationSchema = z.object({
    name: z.string(),
    status: z.custom<Status>(),
    position: z.number(),
  });
  return {
    ProductSchema,
    ProductExtraSchema,
    TableSchema,
    LocationSchema,
    OrderCommentSchema,
    ProductCategorySchema,
    MiscProductSchema,
    EmployeeSchema,
    EmployeeGroupSchema,
    RegisterSettingsSchema,
    CheckoutSchema,
    BookingSchema,
    CompanySettingsFormSchema,
    SetupFormSchema,
    MutationSchema,
    CourseSchema,
    PrintRouteSchema,
  };
};
