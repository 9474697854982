import type { z } from "zod";
import { useTranslations } from "next-intl";

import type {
  CompanySettingsFormSchema,
  SetupFormSchema,
} from "@vrodex/validators";
import { toast } from "@vrodex/ui/toast";

import {
  finishSetupAction,
  updateUserAction,
} from "~/app/_actions/user-actions";
import { useError } from "~/providers/error-provider";
import { useLoading } from "~/providers/loading-provider";

export const useUser = () => {
  const t = useTranslations("User");
  const { setIsLoading } = useLoading();
  const { setError } = useError();
  const finishSetup = async (data: z.infer<typeof SetupFormSchema>) => {
    const { error } = await finishSetupAction(data);
    if (error) {
      console.error(error);
      setError({
        title: "Error",
        description: t("Error.setup_description"),
      });
      return false;
    }
    return true;
  };

  const updateUser = async (
    data: z.infer<typeof CompanySettingsFormSchema>,
  ) => {
    setIsLoading(true);
    const { error } = await updateUserAction(data);
    setIsLoading(false);
    if (error) {
      setError({
        title: "Error",
        description: t("Error.update_description"),
      });
      return false;
    }
    toast.success(t("update_success"));
    return true;
  };

  return {
    finishSetup,
    updateUser,
  };
};
