import { de, enGB, fr, nl } from "date-fns/locale";

export const name = "utils";

export function calculateDiscountPercentage(
  oldPrice: number,
  newPrice: number,
) {
  return Math.abs(((newPrice - oldPrice) / oldPrice) * 100);
}

export const getSupportedLocales = () => {
  return [enGB, nl, fr, de];
};

export const getDateFNSLocale = (locale: string) => {
  switch (locale) {
    case "nl":
      return nl;
    case "de":
      return de;
    case "fr":
      return fr;
    default:
      return enGB;
  }
};

export const isBetweenDates = (date: Date, date1: Date, date2: Date) => {
  if (date >= date1 && date < date2) {
    return true;
  } else {
    return false;
  }
};

export function calculateDiscountedPrice(
  totalPrice: number,
  percentageDiscount: number,
) {
  return totalPrice - (totalPrice * percentageDiscount) / 100;
}

export function highlightText(text: string, highlightText: string) {
  const regex = new RegExp(highlightText, "gi");
  return text.replace(
    regex,
    (match) => `<span class="bg-yellow-500">${match}</span>`,
  );
}

export function getTextColor(bgColor?: string): string | undefined {
  if (!bgColor) return undefined;

  // Remove any leading '#' character from the text color if present
  const cleanColor = bgColor.replace("#", "");

  // Convert the text color to RGB values
  const r = parseInt(cleanColor.slice(0, 2), 16);
  const g = parseInt(cleanColor.slice(2, 4), 16);
  const b = parseInt(cleanColor.slice(4, 6), 16);

  // Calculate the brightness of the text color using a simple formula
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Determine the background color based on the brightness
  if (brightness > 127) {
    return "#000000"; // Dark background color for bright text
  } else {
    return "#FFFFFF"; // Light background color for dark text
  }
}

export const darkenColor = (color: string, factor: number): string => {
  // Remove any leading '#' character from the color if present
  const cleanColor = color.replace("#", "");

  // Convert the color to RGB values
  let r = parseInt(cleanColor.slice(0, 2), 16);
  let g = parseInt(cleanColor.slice(2, 4), 16);
  let b = parseInt(cleanColor.slice(4, 6), 16);

  // Darken the color by reducing its brightness
  r = Math.round(r * (1 - factor));
  g = Math.round(g * (1 - factor));
  b = Math.round(b * (1 - factor));

  // Ensure the RGB values stay within the valid range
  r = Math.min(Math.max(r, 0), 255);
  g = Math.min(Math.max(g, 0), 255);
  b = Math.min(Math.max(b, 0), 255);

  // Convert the darkened RGB values back to hexadecimal
  const darkenedColor = `#${(r * 256 * 256 + g * 256 + b).toString(16).padStart(6, "0")}`;

  return darkenedColor;
};

export function capitalizeString(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function removeLangTag(str: string) {
  return str.replace(/^\/\w{2}\//, "/");
}

export function secondsToHoursMinutes(seconds: number, showSeconds = true) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  // Format the time as "HH:mm:ss"
  let timeString = `${String(hours).padStart(2, "0")}:${String(
    minutes,
  ).padStart(2, "0")}`;

  if (showSeconds) {
    timeString += `:${String(remainingSeconds).padStart(2, "0")}`;
  }

  return timeString;
}

export const generateRandomNumber = (length: number) => {
  let result = "";
  const characters = "0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const generateRandomString = (length: number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export function formatIntToCurrency(input: number, showCurrency = true) {
  return Intl.NumberFormat("nl", {
    style: showCurrency ? "currency" : "decimal",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(input);
}

export function groupByValue<TData, TGroupValue>(
  items: TData[],
  groupValue: keyof TData,
) {
  return items.reduce((groupedMap, item) => {
    const value = item[groupValue] as TGroupValue;

    if (!groupedMap.has(value)) {
      groupedMap.set(value, []);
    }

    const groupArray = groupedMap.get(value)!;
    groupArray.push(item);

    return groupedMap;
  }, new Map<TGroupValue, TData[]>());
}

export function groupByValues<TData, TGroupValue extends string | number>(
  items: TData[],
  groupValues: (keyof TData)[],
): Map<TGroupValue, TData[]> {
  return items.reduce((groupedMap, item) => {
    const values = groupValues.map((value) => item[value]);
    const valueKey = values.join("|") as TGroupValue; // Combine values into a single key

    if (!groupedMap.has(valueKey)) {
      groupedMap.set(valueKey, []);
    }

    const groupArray = groupedMap.get(valueKey)!;
    groupArray.push(item);

    return groupedMap;
  }, new Map<TGroupValue, TData[]>());
}

/**
 * regular expression to check for valid hour format (01-23)
 */
export function isValidHour(value: string) {
  return /^(0[0-9]|1[0-9]|2[0-3])$/.test(value);
}

/**
 * regular expression to check for valid 12 hour format (01-12)
 */
export function isValid12Hour(value: string) {
  return /^(0[1-9]|1[0-2])$/.test(value);
}

/**
 * regular expression to check for valid minute format (00-59)
 */
export function isValidMinuteOrSecond(value: string) {
  return /^[0-5][0-9]$/.test(value);
}

interface GetValidNumberConfig {
  max: number;
  min?: number;
  loop?: boolean;
}

export function getValidNumber(
  value: string,
  { max, min = 0, loop = false }: GetValidNumberConfig,
) {
  let numericValue = parseInt(value, 10);

  if (!isNaN(numericValue)) {
    if (!loop) {
      if (numericValue > max) numericValue = max;
      if (numericValue < min) numericValue = min;
    } else {
      if (numericValue > max) numericValue = min;
      if (numericValue < min) numericValue = max;
    }
    return numericValue.toString().padStart(2, "0");
  }

  return "00";
}

export function getValidHour(value: string) {
  if (isValidHour(value)) return value;
  return getValidNumber(value, { max: 23 });
}

export function getValid12Hour(value: string) {
  if (isValid12Hour(value)) return value;
  return getValidNumber(value, { max: 12 });
}

export function getValidMinuteOrSecond(value: string) {
  if (isValidMinuteOrSecond(value)) return value;
  return getValidNumber(value, { max: 59 });
}

interface GetValidArrowNumberConfig {
  min: number;
  max: number;
  step: number;
}

export function getValidArrowNumber(
  value: string,
  { min, max, step }: GetValidArrowNumberConfig,
) {
  let numericValue = parseInt(value, 10);
  if (!isNaN(numericValue)) {
    numericValue += step;
    return getValidNumber(String(numericValue), { min, max, loop: true });
  }
  return "00";
}

export function getValidArrowHour(value: string, step: number) {
  return getValidArrowNumber(value, { min: 0, max: 23, step });
}

export function getValidArrowMinuteOrSecond(value: string, step: number) {
  return getValidArrowNumber(value, { min: 0, max: 59, step });
}

export function setMinutes(date: Date, value: string) {
  const minutes = getValidMinuteOrSecond(value);
  date.setMinutes(parseInt(minutes, 10));
  return date;
}

export function setSeconds(date: Date, value: string) {
  const seconds = getValidMinuteOrSecond(value);
  date.setSeconds(parseInt(seconds, 10));
  return date;
}

export function setHours(date: Date, value: string) {
  const hours = getValidHour(value);
  date.setHours(parseInt(hours, 10));
  return date;
}

export type TimePickerType = "minutes" | "seconds" | "hours"; // | "12hours";

export function setDateByType(date: Date, value: string, type: TimePickerType) {
  switch (type) {
    case "minutes":
      return setMinutes(date, value);
    case "seconds":
      return setSeconds(date, value);
    case "hours":
      return setHours(date, value);
    default:
      return date;
  }
}

export function getDateByType(date: Date, type: TimePickerType) {
  switch (type) {
    case "minutes":
      return getValidMinuteOrSecond(String(date.getMinutes()));
    case "seconds":
      return getValidMinuteOrSecond(String(date.getSeconds()));
    case "hours":
      return getValidHour(String(date.getHours()));
    default:
      return "00";
  }
}

export function getArrowByType(
  value: string,
  step: number,
  type: TimePickerType,
) {
  switch (type) {
    case "minutes":
      return getValidArrowMinuteOrSecond(value, step);
    case "seconds":
      return getValidArrowMinuteOrSecond(value, step);
    case "hours":
      return getValidArrowHour(value, step);
    default:
      return "00";
  }
}
