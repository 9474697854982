"use client";

import type { Dispatch, SetStateAction } from "react";
import React, { createContext, useContext, useMemo, useState } from "react";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@vrodex/ui/alert-dialog";

interface IErrorContext {
  setError: Dispatch<
    SetStateAction<{ title: string; description: string } | null>
  >;
}

export const ErrorContext = createContext<IErrorContext | undefined>(undefined);

interface IErrorProvider {
  children: React.ReactNode;
}

export const useError = () => {
  const context = useContext(ErrorContext);

  if (!context) {
    throw new Error("useError must be within a ErrorProvider");
  }

  return context;
};

// TODO: add realtime

export const ErrorProvider = ({ children }: IErrorProvider) => {
  const [error, setError] = useState<{
    title: string;
    description: string;
  } | null>(null);
  const context = useMemo(
    (): IErrorContext => ({
      setError,
    }),
    [],
  );

  return (
    <ErrorContext.Provider value={context}>
      <AlertDialog open={!!error} onOpenChange={() => setError(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{error?.title}</AlertDialogTitle>
            <AlertDialogDescription>
              {error?.description}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction>Ok</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {children}
    </ErrorContext.Provider>
  );
};
