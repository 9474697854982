"use client";

import type { RealtimePostgresChangesPayload } from "@supabase/supabase-js";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRouter } from "next/navigation";
import { parseISO, setDefaultOptions } from "date-fns";
import { useLocale } from "next-intl";

import type { Database, ModuleTypes } from "@vrodex/db";
import { Status } from "@vrodex/db";
import { cn } from "@vrodex/ui";
import { getDateFNSLocale } from "@vrodex/utils";

import type { User } from "~/types";
import { SetupDialog } from "~/app/_components/user/setup-dialog";
import { createClient } from "~/utils/supabase/client";

type DbUser = Database["public"]["Tables"]["user"]["Row"];

interface IUserContext {
  user: User;
  modules: ModuleTypes[];
  signOut: () => Promise<boolean>;
}

export const UserContext = createContext<IUserContext | undefined>(undefined);

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be within a UserProvider");
  }

  return context;
};

export default function UserProvider({
  children,
  user,
}: {
  children: React.ReactNode;
  user: User;
}) {
  const supabase = createClient();

  const router = useRouter();

  const locale = useLocale();

  const [realtimeUser, setRealtimeUser] = useState(user);

  const handleUserUpdate = useCallback(
    async (payload: RealtimePostgresChangesPayload<DbUser>) => {
      if (!user) return;

      if (payload.eventType === "UPDATE") {
        const { data: updatedUser, error } = await supabase
          .from("user")
          .select(
            "companyName:company_name, phoneNumber:phone, streetName:street_name, streetNumber:street_number, zipCode:zip_code, city, country, lat, lon, setupCompletedAt:setup_completed_at, timeZone:timezone, clock, currency, id, subscription:subscription_on_user(modules:module_on_user_subscription(module(type)))",
          )
          .eq("id", payload.new.id)
          .eq("id", user.id)
          .eq(
            "subscription_on_user.module_on_user_subscription.status",
            Status.active,
          )
          .limit(1)
          .maybeSingle();

        if (error ?? !updatedUser) {
          console.warn("could not update user");
          return;
        }
        setRealtimeUser({
          ...updatedUser,
          setupCompletedAt: updatedUser.setupCompletedAt
            ? parseISO(updatedUser.setupCompletedAt)
            : null,
          subscription: updatedUser.subscription?.map((subscription) => ({
            ...subscription,
            modules: subscription.modules?.map((module) => ({
              ...module,
              module: {
                ...module.module,
                type: module.module?.type as ModuleTypes,
              },
            })),
          })),
        });
        return;
      }

      if (payload.eventType === "DELETE") {
        // TODO: deal with user delete when online
        await supabase.auth.signOut();
        router.refresh();
      }
    },
    [router, supabase, user],
  );

  useEffect(() => {
    if (!user) return;

    setDefaultOptions({ locale: getDateFNSLocale(locale) });
  }, [user, locale]);

  useEffect(() => {
    console.log("user changes resub");

    if (!user) return;
    const userChannel = supabase
      .channel("user_changes")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "user",
          filter: `id=eq.${user.id}`,
        },
        (payload) => {
          handleUserUpdate(
            payload as RealtimePostgresChangesPayload<DbUser>,
          ).catch((err) => console.warn(err));
        },
      )
      .subscribe();

    return () => {
      supabase.removeChannel(userChannel).catch((err) => console.warn(err));
    };
  }, [handleUserUpdate, supabase, user]);

  const context = useMemo((): IUserContext => {
    const modules =
      realtimeUser.subscription.flatMap((subscription) =>
        subscription.modules.flatMap((module) => module.module!.type),
      ) ?? [];

    const signOut = async () => {
      const { error } = await supabase.auth.signOut({ scope: "local" });
      if (error) {
        console.error(error);
        return false;
      }
      router.refresh();
      return true;
    };

    return {
      modules,
      user: realtimeUser,
      signOut,
    };
  }, [realtimeUser, supabase.auth, router]);

  return (
    <UserContext.Provider value={context}>
      {!realtimeUser.setupCompletedAt && <SetupDialog />}
      <div className={cn(!realtimeUser.setupCompletedAt && "hidden")}>
        {children}
      </div>
    </UserContext.Provider>
  );
}
