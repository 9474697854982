import type { z } from "zod";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import Image from "next/image";
import logo from "@p/images/logo/logo-3d.png";
import creatingAccountAnimation from "@p/lottie/creating_account.json";
import { MdChevronLeft } from "@react-icons/all-files/md/MdChevronLeft";
import { AnimatePresence, motion } from "framer-motion";
import Lottie from "lottie-react";
import { useLocale, useTranslations } from "next-intl";
import countryList from "react-select-country-list";

import type { SupportedLanguages } from "@vrodex/db";
import { ModuleTypes } from "@vrodex/db";
import { Button } from "@vrodex/ui/button";
import { Card, CardContent } from "@vrodex/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  useForm,
} from "@vrodex/ui/form";
import { Input } from "@vrodex/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@vrodex/ui/select";
import { Switch } from "@vrodex/ui/switch";

import { getAddress } from "~/app/_queries/google";
import { use18nSchema } from "~/hooks/use-i18n-schema";
import { useUser } from "~/hooks/use-user";
import { useUserContext } from "~/providers/user-provider";
import { usePathname, useRouter } from "~/utils/navigation";
import { AnimateChangeInHeight } from "../common/animate-height-container";
import GoogleAddressAutoComplete from "../common/google-address-auto-complete";
import { LanguageSwitcher } from "../language-switcher";

export const SetupDialog = () => {
  const [previousStep, setPreviousStep] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const { SetupFormSchema } = use18nSchema();
  const t = useTranslations("SetupDialog");
  const locale = useLocale();
  const pathname = usePathname();
  const delta = currentStep - previousStep;
  const router = useRouter();
  const { user } = useUserContext();
  const { finishSetup } = useUser();

  // const [settingUp, setSettingUp] = useState(false);

  const countryOptions = useMemo(() => countryList().getData(), []);

  type FieldName = keyof z.infer<typeof SetupFormSchema>;

  const steps: { id: string; fields: FieldName[] }[] = [
    {
      id: "step-1",
      fields: [],
    },
    {
      id: "step-2",
      fields: ["modules"],
    },
    {
      id: "step-3",
      fields: ["companyName", "streetName", "streetNumber", "zipCode", "city"],
    },
  ];

  const form = useForm({
    schema: SetupFormSchema,
    defaultValues: {
      modules: [],
      companyName: user.companyName ?? "",
      country: user.country ?? "",
      streetName: user.streetName ?? "",
      zipCode: user.zipCode ?? "",
      streetNumber: user.streetNumber ?? "",
      city: user.city ?? "",
      lat: user.lat ?? undefined,
      lon: user.lon ?? undefined,
    },
  });

  const onSubmit = form.handleSubmit(async (data) => {
    setCurrentStep(4);
    const result = await finishSetup(data);
    if (result === false) {
      setCurrentStep(0);
    } else {
      router.push("/");
    }
  });

  const handleNextStep = async () => {
    const currentFields = steps[currentStep]?.fields;

    const result = await form.trigger(currentFields, {
      shouldFocus: true,
    });

    if (!result) return;

    if (currentStep == steps.length - 1) {
      const result = await getAddress({
        streetName: form.getValues("streetName"),
        streetNumber: form.getValues("streetNumber"),
        zipCode: form.getValues("zipCode"),
      });

      if (!result) return;

      form.setValue("lat", result.lat);
      form.setValue("lon", result.lng);
      await onSubmit();
      return;
    }

    setPreviousStep(currentStep);
    setCurrentStep((state) => state + 1);
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setPreviousStep(currentStep);
      setCurrentStep((state) => state - 1);
    }
  };

  const modules = form.watch("modules");

  useEffect(() => {
    void form.trigger("modules");
  }, [form, modules]);

  return (
    <div className="flex h-screen flex-col items-center justify-center bg-gradient-to-b from-branding-100 to-branding-700">
      <motion.div
        className="flex items-center justify-center"
        transition={{ duration: 0.85, ease: "easeInOut" }}
        initial={{
          opacity: 0,
          scale: 0,
        }}
        animate={{
          opacity: 1,
          scale: 1,
        }}
      >
        <Card>
          <CardContent className="flex  w-[500px] flex-col overflow-hidden">
            <AnimateChangeInHeight>
              <Form {...form}>
                <form className="flex flex-1 flex-col justify-between gap-4">
                  <AnimatePresence mode="wait">
                    {currentStep === 0 && (
                      <Fragment>
                        <motion.div
                          key="setup"
                          className="flex flex-1 flex-col gap-4"
                          transition={{
                            duration: 0.5,
                          }}
                          exit={{
                            x: "-50%",
                            opacity: 0,
                          }}
                        >
                          <div className="flex flex-col gap-2">
                            <motion.div className="flex items-center justify-center">
                              <Image src={logo} alt="Vrodex" width={150} />
                            </motion.div>
                            <motion.div className="flex items-center justify-center">
                              <span className="text-2xl font-semibold">
                                {t("Step1.title")}
                              </span>
                            </motion.div>
                          </div>

                          <motion.div className="flex max-w-xl items-center justify-center">
                            <span className="text-center">
                              {t("Step1.description")}
                            </span>
                          </motion.div>
                          <motion.div className="flex items-center justify-center">
                            <LanguageSwitcher
                              showFlag={true}
                              showLabel={true}
                              value={locale as SupportedLanguages}
                              onChange={(nextLang) => {
                                router.replace(pathname, { locale: nextLang });
                              }}
                            />
                          </motion.div>
                        </motion.div>
                        <div>
                          {currentStep === 0 && (
                            <motion.div
                              key="setup"
                              className="flex w-full items-center justify-center"
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{
                                opacity: 0,
                              }}
                            >
                              <Button
                                type="button"
                                onClick={handleNextStep}
                                className="w-full"
                                variant="success"
                              >
                                {currentStep !== steps.length - 1 && (
                                  <>
                                    <span>{t("Step1.start_button_label")}</span>
                                  </>
                                )}
                              </Button>
                            </motion.div>
                          )}
                        </div>
                      </Fragment>
                    )}

                    {currentStep === 1 && (
                      <motion.div
                        className="flex flex-col gap-4"
                        key="modules"
                        initial={{
                          x: delta > 0 ? "50%" : "-50%",
                          opacity: 0,
                        }}
                        transition={{
                          duration: 0.5,
                        }}
                        animate={{
                          x: 0,
                          opacity: 100,
                        }}
                        exit={{
                          x: delta > 0 ? "0" : "-50%",
                          opacity: 0,
                        }}
                      >
                        <div className="flex flex-col gap-2">
                          <span className="text-center text-2xl font-semibold">
                            {t("Step2.title")}
                          </span>

                          <span className="text-center">
                            {t("Step2.description")}
                          </span>
                        </div>
                        <FormField
                          control={form.control}
                          name="modules"
                          render={({ field }) => (
                            <div className="flex flex-col gap-2">
                              <FormItem className="flex flex-row items-center justify-between space-y-0 rounded-lg border p-3">
                                <div className="space-y-0.5">
                                  <FormLabel className="text-base">
                                    {t("Step2.Modules.cash_register")}
                                  </FormLabel>
                                </div>
                                <FormControl>
                                  <Switch
                                    checked={field.value.includes(
                                      ModuleTypes.cash_register,
                                    )}
                                    onCheckedChange={(checked) => {
                                      return checked
                                        ? field.onChange([
                                            ...field.value,
                                            ModuleTypes.cash_register,
                                          ])
                                        : field.onChange(
                                            field.value?.filter(
                                              (value) =>
                                                value !==
                                                ModuleTypes.cash_register,
                                            ),
                                          );
                                    }}
                                  />
                                </FormControl>
                              </FormItem>
                              <FormItem className="flex flex-row items-center justify-between space-y-0 rounded-lg border p-3">
                                <div className="space-y-0.5">
                                  <FormLabel className="text-base">
                                    {t("Step2.Modules.bookings")}
                                  </FormLabel>
                                </div>
                                <FormControl>
                                  <Switch
                                    checked={field.value.includes(
                                      ModuleTypes.booking,
                                    )}
                                    onCheckedChange={(checked) => {
                                      return checked
                                        ? field.onChange([
                                            ...field.value,
                                            ModuleTypes.booking,
                                          ])
                                        : field.onChange(
                                            field.value?.filter(
                                              (value) =>
                                                value !== ModuleTypes.booking,
                                            ),
                                          );
                                    }}
                                  />
                                </FormControl>
                              </FormItem>
                              <FormItem className="flex flex-row items-center justify-between space-y-0 rounded-lg border p-3">
                                <div className="space-y-0.5">
                                  <FormLabel className="text-base">
                                    {t("Step2.Modules.ordering")}
                                  </FormLabel>
                                </div>
                                <FormControl>
                                  <Switch
                                    checked={field.value.includes(
                                      ModuleTypes.ordering,
                                    )}
                                    onCheckedChange={(checked) => {
                                      return checked
                                        ? field.onChange([
                                            ...field.value,
                                            ModuleTypes.ordering,
                                          ])
                                        : field.onChange(
                                            field.value?.filter(
                                              (value) =>
                                                value !== ModuleTypes.ordering,
                                            ),
                                          );
                                    }}
                                  />
                                </FormControl>
                              </FormItem>
                              <FormMessage />
                            </div>
                          )}
                        />
                        <FormItem className="flex flex-row items-center justify-between space-y-0 rounded-lg border p-3">
                          <div className="space-y-0.5">
                            <FormLabel className="text-base">
                              {t("Step2.select_all_modules")}
                            </FormLabel>
                          </div>
                          <FormControl>
                            <Switch
                              className="data-[state=checked]:bg-green-500"
                              checked={modules.length === 3}
                              onCheckedChange={(checked) => {
                                return checked
                                  ? form.setValue("modules", [
                                      ModuleTypes.ordering,
                                      ModuleTypes.booking,
                                      ModuleTypes.cash_register,
                                    ])
                                  : form.setValue("modules", []);
                              }}
                            />
                          </FormControl>
                        </FormItem>
                      </motion.div>
                    )}

                    {currentStep === 2 && (
                      <motion.div
                        className="flex flex-col gap-4"
                        key="company"
                        initial={{
                          x: delta > 0 ? "50%" : "-50%",
                          opacity: 0,
                        }}
                        transition={{
                          duration: 0.5,
                        }}
                        animate={{
                          x: 0,
                          opacity: 100,
                        }}
                        exit={{
                          x: delta > 0 ? 0 : "-50%",
                          opacity: 0,
                        }}
                      >
                        <div className="flex flex-col gap-2">
                          <span className="text-center text-2xl font-semibold">
                            {t("Step3.title")}
                          </span>

                          <span className="text-center">
                            {t("Step3.description")}
                          </span>
                        </div>
                        <FormField
                          control={form.control}
                          name="companyName"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>{t("Step3.company")}</FormLabel>
                              <FormControl>
                                <Input
                                  placeholder={t("Step3.company")}
                                  {...field}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="country"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>{t("Step3.country")}</FormLabel>
                              <FormControl>
                                <Select
                                  value={field.value}
                                  onValueChange={field.onChange}
                                >
                                  <SelectTrigger>
                                    <FormControl>
                                      <SelectValue
                                        placeholder={t(
                                          "Step3.country_placeholder",
                                        )}
                                      />
                                    </FormControl>
                                    <SelectContent>
                                      {countryOptions.map((country) => {
                                        return (
                                          <SelectItem
                                            key={country.value}
                                            value={country.value}
                                          >
                                            {country.label}
                                          </SelectItem>
                                        );
                                      })}
                                    </SelectContent>
                                  </SelectTrigger>
                                </Select>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="streetName"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>{t("Step3.street_name")}</FormLabel>
                              <FormControl>
                                <GoogleAddressAutoComplete
                                  className="z-50"
                                  onPlaceSelected={(place) => {
                                    console.log(place);
                                    const streetName =
                                      place.address_components?.find((obj) =>
                                        obj.types.includes("route"),
                                      );

                                    const postalCode =
                                      place.address_components?.find((obj) =>
                                        obj.types.includes("postal_code"),
                                      );

                                    const streetNumber =
                                      place.address_components?.find((obj) =>
                                        obj.types.includes("street_number"),
                                      );

                                    const city = place.address_components?.find(
                                      (obj) => obj.types.includes("locality"),
                                    );

                                    if (postalCode) {
                                      form.setValue(
                                        "zipCode",
                                        postalCode.short_name,
                                      );
                                    }
                                    if (streetNumber) {
                                      form.setValue(
                                        "streetNumber",
                                        streetNumber.short_name,
                                      );
                                    }

                                    if (streetName) {
                                      field.onChange(streetName.short_name);
                                    }

                                    if (city) {
                                      form.setValue("city", city.short_name);
                                    }

                                    // if (postalCode && streetNumber && streetName) {
                                    //   retrieveAddress();
                                    // }
                                  }}
                                  onChange={field.onChange}
                                  value={field.value}
                                  country={form.getValues("country")}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="zipCode"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>{t("Step3.zip_code")}</FormLabel>
                              <FormControl>
                                <Input {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="streetNumber"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>{t("Step3.street_number")}</FormLabel>
                              <FormControl>
                                <Input {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="city"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>{t("Step3.city")}</FormLabel>
                              <FormControl>
                                <Input {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </motion.div>
                    )}

                    {currentStep !== 0 && currentStep !== 4 && (
                      <motion.div
                        key="other"
                        className="flex w-full items-center justify-between gap-2"
                        transition={{ duration: 0.5 }}
                        initial={{
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                        }}
                        exit={{
                          opacity: 0,
                          transition: { delay: 0.25 },
                        }}
                      >
                        <Button type="button" onClick={handlePreviousStep}>
                          <MdChevronLeft />
                        </Button>
                        {currentStep !== steps.length - 1 && (
                          <Button
                            variant="primary"
                            type="button"
                            onClick={handleNextStep}
                          >
                            {t("General.next_button_label")}
                          </Button>
                        )}
                        {currentStep === steps.length - 1 && (
                          <Button
                            type="button"
                            onClick={handleNextStep}
                            className="w-full"
                            variant="success"
                          >
                            {t("General.finish_button")}
                          </Button>
                        )}
                      </motion.div>
                    )}

                    {currentStep === 4 && (
                      <motion.div
                        key="setting-up"
                        className="flex flex-col items-center justify-center gap-2 text-center"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <Lottie
                          className="h-[200px]"
                          animationData={creatingAccountAnimation}
                          loop={true}
                        />
                        <h2 className="font-bold">{t("Finish.title")}</h2>
                        <span>{t("Finish.description")}</span>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <AnimatePresence>
                    {currentStep !== 4 && (
                      <motion.div
                        className="text-center"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.5 }}
                        key="disclaimer"
                      >
                        <span className="text-xs">
                          {t("General.disclaimer")}
                        </span>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </form>
              </Form>
            </AnimateChangeInHeight>
          </CardContent>
        </Card>
      </motion.div>
    </div>
  );
};
