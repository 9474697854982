import React, { forwardRef } from "react";
import { availableLocales } from "next.locales.mjs";

import type { SupportedLanguages } from "@vrodex/db";
import {
  SelectContent,
  SelectItem,
  SelectValue,
  SelectWithTrigger,
} from "@vrodex/ui/select";

import "flag-icons/css/flag-icons.min.css";

interface LanguageSwitcherProps {
  value: SupportedLanguages;
  onChange: (language: SupportedLanguages) => void;
  showFlag?: boolean;
  showLabel?: boolean;
  className?: string;
}

export const LanguageSwitcher = forwardRef<
  HTMLButtonElement,
  LanguageSwitcherProps
>(({ value, onChange, showFlag, className, showLabel }, ref) => {
  return (
    <SelectWithTrigger
      className={className}
      ref={ref}
      value={value}
      onValueChange={onChange}
    >
      <SelectValue />
      <SelectContent>
        {availableLocales.map((locale) => (
          <SelectItem key={locale.code} value={locale.code}>
            <div className="flex flex-row items-center gap-2">
              {showFlag && (
                <span
                  className={`fi fi-${locale.code === "en" ? "gb" : locale.code}`}
                ></span>
              )}
              {showLabel && locale.localName}
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </SelectWithTrigger>
  );
});

LanguageSwitcher.displayName = "LanguageSwitcher";
